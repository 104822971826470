:root {
  --main-font-family: 'Poppins', sans-serif;
  color-scheme: dark;
}

@font-face {
  font-family: poopinsBlack;
  font-style: normal;
  font-weight: normal;
  src: url(https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap);
}

@font-face {
  font-family: poopinsMedium;
  font-style: normal;
  font-weight: normal;
  src: url(../assets/fonts/Poppins/Poppins-Medium.ttf);
}

body {
  scroll-behavior: smooth;
}

.input_poopins_black {
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 2px;
}

.btn_poopins_black {
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 5px;
}

.swal2-confirm {
  color: black !important;
}

.linea_azul {
  color: #14b8ec !important;
  height: 4px !important;
}

/*.modules-login {
  background-image: url(../assets/inicioSesion/IMAGEN-DE-FONDO.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}*/

.mt-10 {
  margin-top: 8% !important;
}

.tns-controls [aria-controls] {
  font-size: 50px;
  /*margin: 0 5px;
  padding: 0 1em;
  height: 2.5em;*/
  color: #000;
  background: gray;
  border-radius: 3px;
  border: 0;
}

.tns-controls {
  position: absolute;
  top: 0;
  bottom: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.tns-nav {
  display: none;
}

.main-font-family {
  font-family: var(--main-font-family);
}

.slick-slider {
  width: 90%;
  margin: auto;
  background: #000;
}

.nav-btn {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 100px !important;
}

.prev-slide.slick-disabled,
.next-slide.slick-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.prev-slide {
  background: url(https://storage.googleapis.com/publickuaa/publickuaa/assets/cate/nav-icon.item.png) no-repeat scroll 0 0;
  left: -33px;
}

.next-slide {
  background: url(https://storage.googleapis.com/publickuaa/publickuaa/assets/cate/nav-icon.item.png) no-repeat scroll -24px 0px;
  right: -33px;
}

.prev-slide:hover {
  background-position: 0px -53px;
}

.next-slide:hover {
  background-position: -24px -53px;
}

.btn-primary {
//   color: #fff;
  background-color: #21bbee !important;
  border-color: #21bbee !important;
}

/* Inicio botón atrás */
.btn-back {
  width: 100%;
}

.btn-back a {
  margin: 2rem;
}

/* Fin botón atrás */



/* Inicio diseño de cada card de vídeos */

.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
  /*margin-left: 10px;*/
}

.imgpopular .imgpopular-info {
  position: absolute;
  opacity: 1;
  color: white;
  width: 100%;
}

.imgpopular .imgpopular-info>p {
  color: rgb(182, 182, 182);
}

.imgpopular .imgpopular-info>h1 {
  margin-top: 5%;
  font-size: 1.5rem;
}

.imgpopular .imgpopular-info>p {
  font-size: 1rem;
}

.imgpopular img {
  border-radius: 6% 6% 0% 0%;
}

.imgpopular-info {
  /*background-image: linear-gradient( hsla(0, 0%, 0%, 0.61), hsla(0, 0%, 0%, 0.61));*/
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 7em;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.281);
}

.zoom {
  transition: 1.5s ease !important;
  -moz-transition: 1.5s ease !important;
  /* Firefox */
  -webkit-transition: 1.5s ease !important;
  /* Chrome - Safari */
  -o-transition: 1.5s ease !important;
  /* Opera */
}

.zoom:hover {
  transform: scale(1.1) !important;
  -moz-transform: scale(1.1) !important;
  /* Firefox */
  -webkit-transform: scale(1.1) !important;
  /* Chrome - Safari */
  -o-transform: scale(1.1) !important;
  /* Opera */
  -ms-transform: scale(1.1) !important;
  /* IE9 */
}

/* Fin diseño de cada card de vídeos */


.play-video {
  font-size: 6rem;
  cursor: pointer;
}

.play-video:hover {
  color: #00b1eb;
}

.image-related {
  transition: transform 3s;
}

.image-related:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

/*Estilos inicio de sesión y registro*/
.register_login_form {
  padding: 20px 2vw;
  background-color: #010202;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 1px solid #14b8ec;
  width: 100%;
  /*margin: 0px 0px 0px -25px;*/
}

.register_login_form_main_title {
  color: #14b8ec;
  font-size: 1.7em;
  font-family: poppins;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 5px;
  margin-top: 5vh;
}

.register_login_main_title {
  color: #14b8ec;
  font-size: 43px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 2px;
  width: 90%;
  text-align: left;
  margin-left: 12%;
  margin-top: 50px !important;
}

.register_login_main_title span {
  font-weight: bold;
}

/*Estilos inicio de sesión y registro*/


.container-mate {
  position: relative;
  display: inline-block;
  text-align: center;
}

.centrado {
  top: 28%;
  left: 22%;
  /*transform: translate(-50%, -50%);*/
}

.centrado, .centrado-modules{
  position: absolute;
  text-align: left;
}

.blue-text {
  color: #14b8ec;
  font-size: 0.9vw;
}

.title-experience {
  font-size: 0.8rem;
  width: 50%;
}

.name {
  background-color: #14b8ec;
  color: black;
  width: 50%;
  font-size: 0.7rem;
  text-align: center;
}

.btn-more {
  border: 1px #14b8ec solid;
  border-radius: 10px 0px 0px 10px;
  color: #14b8ec;
}

.btn-more:hover {
  color: white;
}

.btn-level5 {
  border: 1px #14b8ec solid;
  border-radius: 10px 0px 0px 10px;
  color: #14b8ec;
  font-size: 1.0vw;
}

.btn-level5:hover {
  color: white;
}

.black-btn {
  background-color: black;
  border-radius: 10px;
}

.centrado {
  top: 28%;
  left: 22%;
  /*transform: translate(-50%, -50%);*/
}

.container-mate {
  position: relative;
  display: inline-block;
  text-align: center;
}


@media only screen and (min-width: 1px) {
  .btn-more {
    font-size: 0.5rem;
  }

  .title-experience {
    font-size: 0.7rem;
    width: 70%;
  }

  .name {
    font-size: 0.6rem;
    width: 40%;
  }

  .centrado{
    top: 18%;
    left: 18%;
  }

  .centrado-modules{
    top: 18%;
    left: 16%;
  }

  .sections_title{
    font-size: 1rem;
  }

  .sections_subtitle{
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 576px) {
  .btn-more {
    font-size: 0.7rem;
  }

  .title-experience {
    font-size: 0.9rem;
    width: 65%;
  }

  .name {
    font-size: 0.8rem;
    width: 50%;
  }

  .centrado{
    top: 23%;
    left: 18%;
  }

  .centrado-modules{
    top: 23%;
    left: 16%;
  }

  .sections_title{
    font-size: 1.5rem;
  }

  .sections_subtitle{
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .btn-more {
    font-size: 1rem;
  }

  .title-experience {
    font-size: 1rem;
  }

  .name {
    font-size: 0.9rem;
  }

  .centrado{
    top: 27%;
    left: 18%;
  }

  .centrado-modules{
    top: 27%;
    left: 16%;
  }
  .sections_title{
    font-size: 1.8rem;
  }

  .sections_subtitle{
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 992px) {
  .btn-more {
    font-size: 1rem;
  }

  .title-experience {
    font-size: 1.5rem;
  }

  .name {
    font-size: 1.2rem;
  }

  .blue-text {
    font-size: 0.9rem;
  }

  .centrado{
    top: 18%;
    left: 15%;
  }

  .centrado-modules{
    top: 18%;
    left: 13%;
  }
  .sections_title{
    font-size: 1.9rem;
  }

  .sections_subtitle{
    font-size: 0.9rem;
  }


}

@media only screen and (min-width: 1200px) {
  .btn-more {
    font-size: 1.2rem;
  }

  .title-experience {
    font-size: 1.8rem;
  }

  .name {
    font-size: 1.5rem;
    width: 30%;
  }

  .centrado{
    top: 18%;
    left: 20%;
  }

  .centrado-modules{
    top: 18%;
    left: 13%;
  }

  .blue-text {
    font-size: 1.2rem;
  }

  .sections_title{
    font-size: 2rem;
  }

  .sections_subtitle{
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1500px) {
  .btn-more {
    font-size: 1.5rem;
  }

  .title-experience {
    font-size: 1.8rem;
  }

  .name {
    font-size: 1.5rem;
    width: 30%;
  }

  .centrado{
    top: 24%;
    left: 20%;
  }

  .centrado-modules{
    top: 24%;
    left: 13%;
  }

  .blue-text {
    font-size: 1.2rem;
  }

  .sections_title{
    font-size: 2.2rem;
  }

  .sections_subtitle{
    font-size: 1.2rem;
  }
}



/*@media only screen and (max-width: 600px) {
  .img-instructors{
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .img-instructors{
    width: 100%;
    height: 160px;
  }
}

@media only screen and (min-width: 768px) {
  .img-instructors{
    width: 100%;
    height: 325px;
  }
}

@media only screen and (min-width: 992px) {
  .img-instructors{
    width: 100%;
    height: 450px;
  }
}

@media only screen and (min-width: 1200px) {
  .img-instructors{
    width: 100%;
    height: 650px;
  }
}*/


/*Inicio estilos titulos de cada sección*/

.sections_title {
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 5px;
  color: #14b8ec;
  line-height: 1.4;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}

.sections_subtitle {
  color: white;
  letter-spacing: 3px;
}

.search-input-kuaa{
  width: 100%;
  background-color: transparent;
  border: 2px #00b1eb;
  color: white;
}

/*Fin estilos titulos de cada sección*/

.modules {
  background-color: black;
  color: white;
}

@media only screen and (min-width: 1px) {
  .background-image {
    display: none;
  }

  .background-image-mobile {
    display: block;
    opacity: 0.5;
    height: 100vh;
  }

  .mt-10 {
    margin-top: 25% !important;
  }

  .main-menu {
    top: 0px;
    left: 0;
    right: 0;
  }

  .register_login_main_title {
    font-size: 0.8rem;
    line-height: 19px;
    color: white;
  }

  .register_login_form {
    padding: 20px;
  }

  .linea_azul{
    color: white !important;
  }
}

@media only screen and (min-width: 576px) {
  .background-image {
    display: none;
  }

  .background-image-mobile {
    display: block;
    opacity: 0.5;
    height: 130vh;
  }

  .mt-10 {
    margin-top: 1% !important;
  }

  .main-menu {
    top: 0px;
    left: 0;
    right: 0;
  }

  .register_login_main_title {
    font-size: 2rem;
    line-height: 39px;
    color: white !important;
  }

  .register_login_form {
    padding: 20px;
  }

  .linea_azul{
    color: white;
  }
}

@media only screen and (min-width: 768px) {
  .background-image {
    display: none;
  }

  .background-image-mobile {
    display: block;
    opacity: 0.5;
    height: 130vh;
  }

  .register_login_main_title {
    font-size: 2.2rem;
    line-height: 39px;
    color: white;
  }

  .register_login_form {
    padding: 20px;
  }

  .linea_azul{
    color: white !important;
  }

  .main-menu {
    top: 0px;
    left: 0;
    right: 0;
  }

  .mt-10 {
    margin-top: 1% !important;
  }

  .btn_poopins_black, .register_login_form_main_title, .input_poopins_black {
    font-size: 1rem !important;
  }


  .register_login_form {
    padding: 20px;
  }

  .control-label {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 992px) {
  .background-image {
    display: block;
  }

  .background-image-mobile {
    display: none;
  }

  .register_login_main_title {
    font-size: 1.7rem;
    line-height: 43px;
    color: #14b8ec !important;
  }

  .main-menu {
    top: 0px;
    left: 0;
    right: 0;
  }

  .mt-10 {
    margin-top: 8% !important;
  }

  .btn_poopins_black, .register_login_form_main_title, .input_poopins_black {
    font-size: 1.2rem !important;
  }

  .control-label {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 1200px) {
  .background-image {
    display: block;
  }

  .background-image-mobile {
    display: none;
  }

  .register_login_main_title {
    font-size: 2.5rem;
    line-height: 58px;
  }

  .main-menu {
    top: 0px;
    left: 0;
    right: 0;
  }

  .mt-10 {
    margin-top: 9% !important;
  }

  .input_poopins_black {
    font-size: 1.4rem !important;
  }

  .btn_poopins_black, .register_login_form_main_title {
    font-size: 1.7rem !important;
  }

  .control-label {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 1500px) {
  .background-image {
    display: block;
  }

  .background-image-mobile {
    display: none;
  }

  .register_login_main_title {
    font-size: 2.8rem;
    line-height: 58px;
  }

  .main-menu {
    top: 0px;
    left: 0;
    right: 0;
  }

  .mt-10 {
    margin-top: 15% !important;
  }

  .input_poopins_black {
    font-size: 1.4rem !important;
  }

  .btn_poopins_black, .register_login_form_main_title {
    font-size: 1.7rem !important;
  }

  .control-label {
    font-size: 1.3rem;
  }
}
