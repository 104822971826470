// Color system
$black: #000;
$white: #fff;

$primary:       #14b8ec;
$success:       #3acf8a;
$danger:        #ed5f6d;

// Typography
$font-size-root: 16px;
$font-family-sans-serif: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Body
$body-bg: $black;
$body-color: $white;

// Cards
$card-border-width:                 2px;
$card-border-color:                 $primary !default;
$card-border-radius:                10px 0px 0px 10px;
$card-cap-bg:                       rgba($white, .03) !default;
$card-color:                        $body-color !default;
$card-bg:                           $black !default;

// Inputs
$input-btn-padding-y: .6rem;
$input-bg: #020304;
$input-border-color: $primary;
$input-color: $white;

// $input-group-addon-padding-y: $input-padding-y !default;
// $input-group-addon-padding-x: $input-padding-x !default;
// $input-group-addon-font-weight: $input-font-weight !default;
// $input-group-addon-color: $primary;
$input-group-addon-bg: transparent;
// $input-group-addon-border-color: $input-border-color !default;

// Switches
.form-switch {
  .form-check-input {
    border-color: lightgray;
  }
}

// Selects
$form-select-indicator-color: $white;

// Modals
$modal-content-bg:                  $black !default;
$modal-content-border-color:        $primary !default;

$modal-backdrop-bg:                 $white !default;
$modal-backdrop-opacity:            .3 !default;

// Buttons
$btn-close-color: $white;
$btn-border-radius: 4px;


@import "node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

// Layout & components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/offcanvas";
@import "node_modules/bootstrap/scss/placeholders";

// Helpers
@import "node_modules/bootstrap/scss/helpers";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";
